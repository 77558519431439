import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import grey from '@mui/material/colors/grey';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useGetCurrenciesFromCountry } from '../../hooks/useGetCurrencies';

const CurrencyFilter = ({ currencies, currency, setCurrency, showAll, sx }) => {
  const availableCurrencies = currencies || useGetCurrenciesFromCountry();

  if (availableCurrencies.length <= 1) {
    return null;
  }

  useEffect(() => {
    if (!currency) {
      setCurrency(showAll ? '' : availableCurrencies[0].isoCode);
    }
  }, []);

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <Select
      value={currency}
      onChange={handleCurrencyChange}
      variant="outlined"
      sx={{
        minWidth: 75,
        bgcolor: grey[900],
        color: 'white',
        '& .MuiSelect-select': {
          padding: '8px 24px 8px 12px',
        },
        '& .MuiSvgIcon-root': {
          color: 'white',
        },
        ...sx,
      }}
    >
      {showAll && (
        <MenuItem
          value=""
          sx={{
            bgcolor: currency === '' ? grey[900] : 'background.paper',
          }}
        >
          Todos
        </MenuItem>
      )}
      {availableCurrencies.map((cur) => (
        <MenuItem
          key={cur.id}
          value={cur.isoCode}
          sx={{
            bgcolor: currency === cur.isoCode ? grey[900] : 'background.paper',
          }}
        >
          {cur.isoCode}
        </MenuItem>
      ))}
    </Select>
  );
};

CurrencyFilter.propTypes = {
  currencies: PropTypes.arrayOf(
    PropTypes.shape({ isoCode: PropTypes.string, id: PropTypes.string }),
  ),
  currency: PropTypes.string,
  setCurrency: PropTypes.func.isRequired,
  showAll: PropTypes.bool,
  sx: PropTypes.shape({}),
};

CurrencyFilter.defaultProps = {
  currencies: null,
  currency: '',
  showAll: false,
  sx: {},
};

export default CurrencyFilter;
